<template>
  <div class="column is-12">
    <img src="/images/logo-full.png" />
    <br />
    Back End version: {{ backendVersion ? backendVersion : '-' }}
    <br />
    Front End version: {{ APP_VERSION }}
  </div>
</template>
<script>
import ApiService from '@/services/common/api.service'
export default {
  name: 'Version',
  data() {
    return {
      APP_VERSION: process.env.VUE_APP_VERSION
        ? process.env.VUE_APP_VERSION
        : '-',
      backendVersion: '',
    }
  },

  methods: {
    getVersion() {
      ApiService.get('/api-version').then((response) => {
        this.backendVersion = response.data
      })
    },
  },

  mounted() {
    this.getVersion()
  },
}
</script>
